<!--
 * @Author: 蒋威
 * @Date: 2022-04-26 09:08:46
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-06-17 10:35:23
 * @Description: 轮播图
-->
<template>
  <el-carousel
    :autoplay="true"
    :interval="3000"
    v-if="bannerList.length > 0"
    :arrow="bannerList.length > 1 ? 'always' : 'never'"
  >
    <el-carousel-item v-for="(item, index) in bannerList" :key="index">
      <img
        @click="gotojump(item)"
        class="carousel-img"
        v-lazy="item.fileUrl"
        alt=""
        srcset=""
        :class="item.url ? 'pointer' : ''"
      />
    </el-carousel-item>
  </el-carousel>
</template>

<script>
import { websiteAds_list } from '@/api/homePage/index.js'
export default {
  name: 'elRotation',
  props: {
    modelId: {
      type: Array | String,
    },
  },
  data() {
    return {
      bannerList: [
        //   {
        //     file_url:
        //       'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
        //   },
      ],
      // 页面可视区高度
      clientHeight: '',
    }
  },
  methods: {
    // 获取轮播
    async getBannerList() {
      const res = await websiteAds_list({
        banner_type: 1,
        page: 1,
        limit: 5,
      })
      if (res.code === this.$httpCode) {
        this.bannerList = res.data.list && res.data.list.slice(0, 5)
      }
    },
    /**
     * @Auth: 蒋威
     * @Date: 2022-04-19 16:44:18
     * @Description: 轮播图跳转
     */
    gotojump(item) {
      if (item.url) {
        window.open(item.url, '_blank')
      }
    },
  },
  created() {
    this.getBannerList()
  },
}
</script>

<style lang="scss" scoped>
.carousel-img {
  width: 100%;
  height: 100%;
}
.el-carousel {
  // height: 640px;
  height: 33.33vw;
  ::v-deep .el-carousel__container {
    height: 100%;
  }
  ::v-deep .el-carousel__arrow.el-carousel__arrow--left {
    left: 14%;
    width: 40px;
    height: 40px;
    background: rgba(102, 102, 102, 0.39);
    border-radius: 50%;
    .el-icon-arrow-left {
      font-size: 24px;
      &::before {
        content: '\e6ea';
      }
    }
  }
  ::v-deep .el-carousel__arrow.el-carousel__arrow--right {
    right: 14%;
    width: 40px;
    height: 40px;
    background: rgba(102, 102, 102, 0.39);
    border-radius: 50%;
    .el-icon-arrow-right {
      font-size: 24px;
      &::before {
        content: '\e6e9';
      }
    }
  }
  ::v-deep .el-carousel__indicator {
    width: 60px;
    height: 6px;
    padding: 0;
    background: rgba(255, 255, 255, 0.39);
    // opacity: 0.3;
    border-radius: 3px;
    margin-right: 10px;
    // position: relative;
  }
  // ::v-deep .el-carousel__indicator.is-active .el-carousel__button  {
  ::v-deep .el-carousel__indicator .el-carousel__button {
    display: none;
    width: 23px;
    height: 6px;
    padding: 0;
    bottom: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.39);
    opacity: 1;
    border-radius: 3px;
  }
  ::v-deep .el-carousel__indicator.is-active .el-carousel__button {
    width: 23px;
    height: 6px;
    padding: 0;
    display: block;
    background: rgba(255, 255, 255, 0.8);
    opacity: 1;
    border-radius: 3px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 940px) {
  .carousel-img {
    width: 100%;
    height: 100%;
  }
  .el-carousel {
    height: 33.33vw;
    // height: 33.33vw;
    ::v-deep .el-carousel__container {
      height: 100%;
    }
  }
}
@media only screen and (max-width: 768px) {
  .carousel-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .el-carousel {
    // height: 50vw;
    margin-top: 10px;
    height: 50vw;
    ::v-deep .el-carousel__container {
      height: 100%;
    }
    ::v-deep .el-carousel__indicator {
      width: 20px;
    }
    ::v-deep.el-carousel__indicators--horizontal {
      bottom: 10px;
    }
    ::v-deep .el-carousel__indicator.is-active .el-carousel__button {
      width: 8px;
    }
  }
}
</style>