<!--
 * @Author: 蒋威
 * @Date: 2022-04-07 09:59:39
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-26 17:01:58
 * @Description: 首页
-->
<template>
  <div class="homepage-wrap">
    <!-- 轮播图 -->
    <ElRotation />
    <!-- 数据统计 -->
    <DataStatistics :website="website" v-if="website && website.id" />
    <!-- 产品服务 -->
    <Product
      v-if="
        website &&
        website.homeShows &&
        new Array(website.homeShows)[0].includes('1')
      "
    />
    <!-- 案例成果 -->
    <Case
      v-if="
        website &&
        website.homeShows &&
        new Array(website.homeShows)[0].includes('2')
      "
    />
    <!-- 公司资讯 -->
    <Information
      v-if="
        website &&
        website.homeShows &&
        new Array(website.homeShows)[0].includes('3')
      "
    />
    <!-- 合作伙伴 -->
    <Cooperation
      v-if="
        website &&
        website.homeShows &&
        new Array(website.homeShows)[0].includes('4')
      "
    />
  </div>
</template>

<script>
import ElRotation from '@/components/home/ElRotation.vue'
import DataStatistics from '@/components/home/DataStatistics.vue'
import Product from '@/components/home/Product.vue'
import Case from '@/components/home/Case.vue'
import Information from '@/components/home/Information.vue'
import Cooperation from '@/components/home/Cooperation.vue'
import { channelTree } from '@/api/menu/index.js'
import { website_list } from '@/api/homePage/index.js'

export default {
  name: 'HomePage',
  components: {
    ElRotation,
    DataStatistics,
    Product,
    Case,
    Information,
    Cooperation,
  },
  data() {
    return {
      menuList: [],
      //网站配置
      website: {},
    }
  },
  methods: {
    async getMenuList() {
      const res = await channelTree({ diff: 'web' })
      if (res.code === this.$httpCode) {
        this.menuList = res.data
        // for (let index = 0; index < this.menuList.length; index++) {
        //   if (this.menuList[index].model == 'home') {
        //     this.homeModelId = this.menuList[index].id
        //   }
        // }
      }
    },
    // 获取网站配置信息
    async getwebsite_list() {
      const res = await website_list()
      if (res.code === this.$httpCode) {
        this.website = res.data
        document.title = this.website.title || '普益云公益管理平台'
      }
    },
  },
  created() {
    this.getwebsite_list()
  },
}
</script>

<style lang="scss" scoped>
.homepage-wrap {
  width: 100%;
}
</style>
