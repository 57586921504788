<!--
 * @Author: 蒋威
 * @Date: 2022-05-23 14:17:54
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-08-08 10:41:40
 * @Description: 案例成果
-->
<template>
  <div class="homecase-container" v-show="list.length > 0">
    <div class="box-title wow bounceInUp">案例分享</div>
    <div
      class="homecase-wrap"
      :style="list.length == 1 ? 'justify-content: center;' : ''"
    >
      <template v-for="(item, index) in list">
        <div
          v-if="index < 3"
          class="homecase-item pointer wow slideInLeft"
          :key="index"
          data-wow-delay="0.1s"
          @click="toDetail(item)"
        >
          <img v-lazy="item.fileUrl" alt="" />
          <div class="homecase-infobox">
            <div class="homecase-title ellipsis" :title="item.title">
              {{ item.title }}
            </div>
            <div class="homecase-textinfo ellipsis-3">
              {{ item.description }}
            </div>
            <div class="jumpIcon-box">
              <i class="el-icon-right"></i>
            </div>
          </div>
        </div>
      </template>
      <!-- <div class="arrow-box" @click="pageTurning($event)">
        <span class="arrow-container pointer">
          <i class="iconfont icon-xialajiantouxiao"></i>
        </span>
      </div> -->
    </div>
  </div>
</template>

<script>
import { homeNews } from '@/api/homePage/index.js'
import Arrow from '@/components/home/Arrow.vue'
export default {
  inject: ['reload'],
  components: {
    Arrow,
  },
  data() {
    return {
      dataList: [
        {
          fileUrl:
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
        },
      ],
      list: [],
    }
  },
  methods: {
    // 下一屏
    pageTurning(e) {
      const fontSize = window.document.documentElement.style.fontSize.replace(
        'px',
        ''
      )
      document.documentElement.scrollTop = e.pageY - (24 * fontSize) / 100
    },
    async homeNews() {
      const res = await homeNews(2)

      if (res.code === this.$httpCode) {
        this.list = res.data || []
      }
    },
    toDetail(item) {
      localStorage.setItem('dcrumbName', item.title)
      this.reload()
      this.$router.push({
        name: 'newsInfo',
        query: { id: item.id },
      })
    },
  },
  created() {
    this.homeNews()
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/fontsize.scss';
.homecase-container {
  padding-top: 88px;
  .box-title {
    height: 42px;
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 42px;
    color: #202020;
    text-align: center;
    margin-bottom: 60px;
  }
  .homecase-wrap {
    display: flex;
    height: auto;
    position: relative;
    .homecase-item {
      flex: 0 0 33.33%;
      height: 33.33vw;
      overflow: hidden;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        // transform: translateX(-20%);
        transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
      }
      .homecase-infobox {
        position: absolute;
        bottom: 50%;
        transform: translateY(50%);
        left: 100px;
        right: 100px;
        .homecase-title {
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          text-align: center;
        }
        .homecase-textinfo {
          text-align: center;
          margin-top: 30px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 24px;
          color: #ffffff;
        }
        .jumpIcon-box {
          text-align: center;
          margin-top: 30px;
          display: none;
          i {
            width: 40px;
            height: 40px;
            border: 2px solid #ffffff;
            border-radius: 50%;
            display: inline-block;
            font-size: 24px;
            color: #fff;
            line-height: 36px;
          }
        }
      }
      &::after {
        content: '';
        width: 100%;
        height: 0;
        background-color: rgba(10, 36, 86, 0.4);
        position: absolute;
        top: 0px;
        opacity: 0;
        transition: all 0.3s ease 0s;
      }
      &:hover {
        img {
          transform: scale(1.1, 1.1);
        }
        .homecase-infobox {
          .jumpIcon-box {
            display: block;
          }
        }
        &::after {
          opacity: 1;
          height: 100%;
        }
      }
    }
  }
}
.arrow-box {
  position: absolute;
  text-align: center;
  bottom: 40px;
  left: 50%;
  transform: translateX(-20px);
  width: 50px;
  height: 50px;
  .arrow-container {
    margin-top: 5px;
    display: inline-block;
    transition: all 0.3s;
    width: 40px;
    height: 40px;
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid #ececec;
    border-radius: 50%;
    opacity: 0.8;
    margin-top: 5;
    i {
      color: #666666;
      font-size: 20px;
      font-weight: 700;
      line-height: 40px;
    }
    &:hover {
      transform: scale(1.1, 1.1);
    }
  }
}
@media only screen and (min-width: 1080px) and (max-width: 1299px) {
  .homecase-container {
    padding-top: 88px;
    .box-title {
      height: 42px;
      font-size: 32px;
      line-height: 42px;
      text-align: center;
      margin-bottom: 60px;
    }
    .homecase-wrap {
      display: flex;
      height: auto;
      position: relative;
      .homecase-item {
        flex: 0 0 33.33%;
        overflow: hidden;
        position: relative;
        height: 33.33vw;
        img {
          width: 100%;
          height: 100%;
          transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
        }
        .homecase-infobox {
          position: absolute;
          bottom: 50%;
          transform: translateY(50%);
          left: 80px;
          right: 80px;
          .homecase-title {
            font-size: 22px;
          }
          .homecase-textinfo {
            text-align: center;
            margin-top: 20px;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
          }
          .jumpIcon-box {
            text-align: center;
            margin-top: 30px;
            display: none;
            i {
              width: 40px;
              height: 40px;
              border: 2px solid #ffffff;
              border-radius: 50%;
              display: inline-block;
              font-size: 24px;
              color: #fff;
              line-height: 36px;
            }
          }
        }
        &::after {
          content: '';
          width: 100%;
          height: 0;
          background-color: rgba(10, 36, 86, 0.4);
          position: absolute;
          top: 0px;
          opacity: 0;
          transition: all 0.3s ease 0s;
        }
        &:hover {
          img {
            transform: scale(1.1, 1.1);
          }
          .homecase-infobox {
            .jumpIcon-box {
              display: block;
            }
          }
          &::after {
            opacity: 1;
            height: 100%;
          }
        }
      }
    }
  }
  .arrow-box {
    position: absolute;
    text-align: center;
    bottom: 10px;
    left: 50%;
    transform: translateX(-20px);
    width: 50px;
    height: 50px;
    .arrow-container {
      margin-top: 5px;
      display: inline-block;
      transition: all 0.3s;
      width: 40px;
      height: 40px;
      background: rgba(255, 255, 255, 0.8);
      border: 1px solid #ececec;
      border-radius: 50%;
      opacity: 0.8;
      margin-top: 5;
      i {
        color: #666666;
        font-size: 20px;
        font-weight: 700;
        line-height: 40px;
      }
      &:hover {
        transform: scale(1.1, 1.1);
      }
    }
  }
}
@media only screen and (min-width: 941px) and (max-width: 1079px) {
  .homecase-container {
    padding-top: 88px;
    .box-title {
      height: 42px;
      font-size: 32px;
      line-height: 42px;
      text-align: center;
      margin-bottom: 60px;
    }
    .homecase-wrap {
      display: flex;
      height: auto;
      position: relative;
      .homecase-item {
        flex: 0 0 33.33%;
        overflow: hidden;
        position: relative;
        height: 33.33vw;
        img {
          width: 100%;
          height: 100%;
          transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
        }
        .homecase-infobox {
          position: absolute;
          bottom: 50%;
          transform: translateY(50%);
          left: 60px;
          right: 60px;
          .homecase-title {
            font-size: 20px;
          }
          .homecase-textinfo {
            text-align: center;
            margin-top: 14px;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
          }
          .jumpIcon-box {
            text-align: center;
            margin-top: 30px;
            display: none;
            i {
              width: 40px;
              height: 40px;
              border: 2px solid #ffffff;
              border-radius: 50%;
              display: inline-block;
              font-size: 24px;
              color: #fff;
              line-height: 36px;
            }
          }
        }
        &::after {
          content: '';
          width: 100%;
          height: 0;
          background-color: rgba(10, 36, 86, 0.4);
          position: absolute;
          top: 0px;
          opacity: 0;
          transition: all 0.3s ease 0s;
        }
        &:hover {
          img {
            transform: scale(1.1, 1.1);
          }
          .homecase-infobox {
            .jumpIcon-box {
              display: block;
            }
          }
          &::after {
            opacity: 1;
            height: 100%;
          }
        }
      }
    }
  }
  .arrow-box {
    position: absolute;
    text-align: center;
    bottom: 8px;
    left: 50%;
    transform: translateX(-20px);
    width: 50px;
    height: 50px;
    .arrow-container {
      margin-top: 5px;
      display: inline-block;
      transition: all 0.3s;
      width: 40px;
      height: 40px;
      background: rgba(255, 255, 255, 0.8);
      border: 1px solid #ececec;
      border-radius: 50%;
      opacity: 0.8;
      margin-top: 5;
      i {
        color: #666666;
        font-size: 20px;
        font-weight: 700;
        line-height: 40px;
      }
      &:hover {
        transform: scale(1.1, 1.1);
      }
    }
  }
}
@media only screen and (min-width: 769px) and (max-width: 940px) {
  .homecase-container {
    padding-top: 30px;
    .box-title {
      height: 42px;
      font-size: 28px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 42px;
      color: #202020;
      text-align: center;
      margin-bottom: 40px;
    }
    .homecase-wrap {
      display: flex;
      position: relative;
      .homecase-item {
        flex: 0 0 33.33%;
        overflow: hidden;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
        }
        .homecase-infobox {
          position: absolute;
          bottom: 50%;
          transform: translateY(50%);
          left: 30px;
          right: 30px;
          .homecase-title {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ffffff;
          }
          .homecase-textinfo {
            margin-top: 10px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 18px;
            color: #ffffff;
          }
          .jumpIcon-box {
            text-align: center;
            margin-top: 10px;
            display: none;
            i {
              width: 30px;
              height: 30px;
              border: 1px solid #ffffff;
              border-radius: 50%;
              display: inline-block;
              font-size: 20px;
              color: #fff;
              line-height: 28px;
            }
          }
        }
        &::after {
          content: '';
          width: 100%;
          height: 0;
          background-color: rgba(10, 36, 86, 0.4);
          position: absolute;
          top: 0px;
          opacity: 0;
          transition: all 0.3s ease 0s;
        }
        &:hover {
          img {
            transform: scale(1.1, 1.1);
          }
          .homecase-infobox {
            .jumpIcon-box {
              display: block;
            }
          }
          &::after {
            opacity: 1;
            height: 100%;
          }
        }
      }
    }
  }
  .arrow-box {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .homecase-container {
    padding-top: 0px;
    .box-title {
      height: 42px;
      font-size: 28px;
      line-height: 42px;
      margin-bottom: 40px;
    }
    .homecase-wrap {
      display: block;
      position: relative;
      width: 96%;
      margin: 0 auto;
      .homecase-item {
        overflow: hidden;
        position: relative;
        margin-bottom: 10px;
        height: auto;
        img {
          width: 100%;
          height: auto;
          transition: none;
        }
        .homecase-infobox {
          position: absolute;
          bottom: 50%;
          transform: translateY(50%);
          left: 80px;
          right: 80px;
          .homecase-title {
            font-size: 16px;
          }
          .homecase-textinfo {
            margin-top: 20px;
            font-size: 14px;
            line-height: 18px;
          }
          .jumpIcon-box {
            text-align: center;
            margin-top: 30px;
            display: none;
            i {
              width: 40px;
              height: 40px;
              border: 2px solid #ffffff;
              border-radius: 50%;
              display: inline-block;
              font-size: 24px;
              color: #fff;
              line-height: 36px;
            }
          }
        }
        &::after {
          content: '';
          width: 100%;
          height: 0;
          background-color: rgba(10, 36, 86, 0.4);
          position: absolute;
          top: 0px;
          opacity: 0;
          transition: all 0.3s ease 0s;
        }
        &:hover {
          img {
            transform: none;
          }
          .homecase-infobox {
            .jumpIcon-box {
              display: block;
            }
          }
          &::after {
            opacity: 1;
            height: 100%;
          }
        }
      }
    }
  }
  .arrow-box {
    display: none;
  }
}
</style>