<!--
 * @Author: 蒋威
 * @Date: 2022-05-23 14:53:23
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-08-08 10:39:00
 * @Description: 合作伙伴
-->
<template>
  <div class="homeCooperation-container" v-show="list.length > 0">
    <div class="response homeCooperation-wrap">
      <div class="box-title wow bounceInUp">合作伙伴</div>
      <div class="partner-wrap">
        <template v-for="(item, index) in list">
          <div
            data-wow-delay="0.1s"
            :title="item.name"
            class="partner-item pointer wow bounceInUp"
            :key="index"
          >
            <img v-lazy="item.fileUrl" alt="" :title="item.name" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { cooperator_list } from '@/api/homePage/index.js'
export default {
  data() {
    return {
      datalist: [
        {
          file_url:
            'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
        },
      ],
      list: [],
    }
  },
  methods: {
    async getList() {
      const res = await cooperator_list({
        limit: 9999,
        page: 1,
        type: 1,
      })

      if (res.code === this.$httpCode) {
        this.list = res.data.list || []
      }
    },
  },
  created() {
    this.getList()
  },
}
</script>

<style  lang="scss" scoped>
@import '@/assets/css/fontsize.scss';
.homeCooperation-container {
  .homeCooperation-wrap {
    margin: 108px auto 40px auto;
    .box-title {
      height: 42px;
      font-size: 32px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 42px;
      color: #202020;
      text-align: center;
      margin-bottom: 80px;
    }
    .partner-wrap {
      display: flex;
      flex-wrap: wrap;
      .partner-item {
        flex: 0 0 224px;
        -ms-flex: 0 0 224px;
        height: 86px;
        margin-bottom: 70px;
        // margin-bottom: 60px;
        overflow: hidden;
        margin-right: 44px;
        text-align: center;
        transition: all 0.5s ease;
        // border: 1px dashed rgba(0, 0, 0, 0.3);
        &:nth-child(5n) {
          margin-right: 0;
        }
        img {
          width: 100%;
          height: 100%;
        }
        &:hover {
          transform: translateY(-14px);
        }
      }
    }
  }
}
@media only screen and (min-width: 1080px) and (max-width: 1299px) {
  .homeCooperation-container {
    .homeCooperation-wrap {
      margin: 108px auto 40px auto;
      .box-title {
        height: 42px;
        font-size: 32px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 42px;
        color: #202020;
        text-align: center;
        margin-bottom: 80px;
      }
      .partner-wrap {
        display: flex;
        flex-wrap: wrap;
        .partner-item {
          flex: 0 0 18%;
          -ms-flex: 0 0 18%;
          height: auto;
          margin-bottom: 50px;
          // margin-bottom: 60px;
          overflow: hidden;
          margin-right: 2.5%;
          text-align: center;
          transition: all 0.5s ease;
          // border: 1px dashed rgba(0, 0, 0, 0.3);
          &:nth-child(5n) {
            margin-right: 0;
          }
          img {
            width: 100%;
            height: auto;
          }
          &:hover {
            transform: translateY(-14px);
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 941px) and (max-width: 1079px) {
  .homeCooperation-container {
    .homeCooperation-wrap {
      margin: 108px auto 40px auto;
      .box-title {
        height: 42px;
        font-size: 32px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 42px;
        color: #202020;
        text-align: center;
        margin-bottom: 60px;
      }
      .partner-wrap {
        display: flex;
        flex-wrap: wrap;
        .partner-item {
          flex: 0 0 18%;
          -ms-flex: 0 0 18%;
          height: auto;
          margin-bottom: 50px;
          // margin-bottom: 60px;
          overflow: hidden;
          margin-right: 2.5%;
          text-align: center;
          transition: all 0.5s ease;
          // border: 1px dashed rgba(0, 0, 0, 0.3);
          &:nth-child(5n) {
            margin-right: 0;
          }
          img {
            width: 100%;
            height: auto;
          }
          &:hover {
            transform: translateY(-14px);
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 769px) and (max-width: 940px) {
  .homeCooperation-container {
    .homeCooperation-wrap {
      margin: 108px auto 40px auto;
      .box-title {
        height: 42px;
        font-size: 28px;
        line-height: 42px;
        color: #202020;
        text-align: center;
        margin-bottom: 40px;
      }
      .partner-wrap {
        display: flex;
        flex-wrap: wrap;
        .partner-item {
          flex: 0 0 18%;
          -ms-flex: 0 0 18%;
          height: auto;
          margin-bottom: 30px;
          // margin-bottom: 60px;
          overflow: hidden;
          margin-right: 2.5%;
          text-align: center;
          transition: all 0.5s ease;
          // border: 1px dashed rgba(0, 0, 0, 0.3);
          &:nth-child(5n) {
            margin-right: 0;
          }
          img {
            width: 100%;
            height: auto;
          }
          &:hover {
            transform: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .homeCooperation-container {
    .homeCooperation-wrap {
      margin: 60px auto 40px auto;
      .box-title {
        height: 42px;
        font-size: 28px;
        line-height: 42px;
        color: #202020;
        text-align: center;
        margin-bottom: 40px;
      }
      .partner-wrap {
        display: flex;
        flex-wrap: wrap;
        .partner-item {
          flex: 0 0 49%;
          -ms-flex: 0 0 49%;
          height: auto;
          margin-bottom: 30px;
          // margin-bottom: 60px;
          overflow: hidden;
          margin-right: 2%;
          text-align: center;
          transition: all 0.5s ease;
          &:nth-child(5n) {
            margin-right: 2%;
          }
          &:nth-child(2n) {
            margin-right:0;
          }
          img {
            width: 100%;
            height: auto;
          }
          &:hover {
            transform: none;
          }
        }
      }
    }
  }
}
</style>