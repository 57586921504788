var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"homepage-wrap"},[_c('ElRotation'),(_vm.website && _vm.website.id)?_c('DataStatistics',{attrs:{"website":_vm.website}}):_vm._e(),(
      _vm.website &&
      _vm.website.homeShows &&
      new Array(_vm.website.homeShows)[0].includes('1')
    )?_c('Product'):_vm._e(),(
      _vm.website &&
      _vm.website.homeShows &&
      new Array(_vm.website.homeShows)[0].includes('2')
    )?_c('Case'):_vm._e(),(
      _vm.website &&
      _vm.website.homeShows &&
      new Array(_vm.website.homeShows)[0].includes('3')
    )?_c('Information'):_vm._e(),(
      _vm.website &&
      _vm.website.homeShows &&
      new Array(_vm.website.homeShows)[0].includes('4')
    )?_c('Cooperation'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }