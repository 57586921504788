<!--
 * @Author: 蒋威
 * @Date: 2022-05-23 09:32:08
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-07-04 16:22:29
 * @Description: 产品与服务
-->
<template>
  <div class="homeproduct-container" v-show="list.length > 0">
    <div class="response homeproduct-box">
      <div class="box-title wow bounceInUp">产品及服务</div>
      <div class="homeproduct-wrap">
        <template v-for="(item, index) in list">
          <div
            v-if="index < 6"
            data-wow-delay="0.1s"
            class="homeproduct-item pointer wow bounceInUp"
            :key="index"
            @click="toDetail(item)"
          >
            <div class="homeproduct-imgbox">
              <img v-lazy="item.fileUrl" alt="" />
            </div>
            <div class="homeproduct-infobox">
              <div class="homeproduct-title ellipsis" :title="item.title">
                {{ item.title }}
              </div>
              <div class="homeproduct-textinfo ellipsis-3">
                {{ item.description }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- <Arrow /> -->
  </div>
</template>

<script>
import { homeNews } from '@/api/homePage/index.js'
import Arrow from '@/components/home/Arrow.vue'
export default {
  inject: ['reload'],
  components: {
    Arrow,
  },
  data() {
    return {
      dataList: [
        {
          title: 'dsgghdhfh',
          description: 'dsgghdhfh',
          fileUrl:
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
        },
      ],
      list: [],
    }
  },
  methods: {
    async homeNews() {
      const res = await homeNews(1)

      if (res.code === this.$httpCode) {
        this.list = res.data || []
      }
    },
    toDetail(item) {
      localStorage.setItem('dcrumbName', item.title)
      this.reload()
      this.$router.push({
        name: 'newsInfo',
        query: { id: item.id },
      })
    },
  },
  created() {
    this.homeNews()
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/fontsize.scss';
.homeproduct-container {
  padding: 90px 0 30px 0;
  animation-delay: 20s;
  animation-name: fadeInUp;
  .homeproduct-box {
    margin-bottom: 0px;
    // margin-bottom: 108px;
    .box-title {
      height: 42px;
      font-size: 32px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 42px;
      color: #202020;
      text-align: center;
      margin-bottom: 60px;
    }
    .homeproduct-wrap {
      display: flex;
      flex-wrap: wrap;
      .homeproduct-item {
        // width: 410px;
        // flex: 0 0 410px;
        flex: 0 0 410px;
        -ms-flex: 0 0 410px;
        margin-right: 35px;
        margin-bottom: 30px;
        position: relative;
        overflow: hidden;
        .homeproduct-imgbox {
          width: 100%;
          height: auto;
          overflow: hidden;
          img {
            width: 100%;
            height: auto;
            transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
          }
        }
        .homeproduct-infobox {
          box-sizing: content-box;
          padding: 0 20px 50px 30px;
          background:  rgba(248, 248, 248, 0.39);;
          .homeproduct-title {
            box-sizing: content-box;
            padding-top: 32px;
            height: 24px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            line-height: 24px;
            color: #202020;
          }
          .homeproduct-textinfo {
            margin-top: 24px;
            height: 72px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 24px;
            color: #666666;
          }
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
        &:hover {
          box-shadow: 0px 12px 64px rgb(0, 0, 0, 0.1);
          .homeproduct-imgbox {
            img {
              transform: scale(1.1, 1.1);
            }
          }
          .homeproduct-infobox {
            background-color: #5cc1a5;
            .homeproduct-title {
              color: #ffffff;
            }
            .homeproduct-textinfo {
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1080px) and (max-width: 1299px) {
  .homeproduct-container {
    padding: 90px 0 30px 0;
    .homeproduct-box {
      margin-bottom: 0px;
      .box-title {
        height: 42px;
        font-size: 32px;
        line-height: 42px;
        text-align: center;
        margin-bottom: 60px;
      }
      .homeproduct-wrap {
        display: flex;
        .homeproduct-item {
          flex: 0 0 32%;
          -ms-flex: 0 0 32%;
          margin-right: 2%;
          position: relative;
          overflow: hidden;
          .homeproduct-imgbox {
            width: 100%;
            height: auto;
            overflow: hidden;
            img {
              width: 100%;
              height: auto;
              transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
            }
          }
          .homeproduct-infobox {
            box-sizing: content-box;
            padding: 0 20px 50px 30px;
            .homeproduct-title {
              box-sizing: content-box;
              padding-top: 32px;
              height: 24px;
              font-size: 18px;
              line-height: 24px;
            }
            .homeproduct-textinfo {
              margin-top: 24px;
              height: 72px;
              font-size: 16px;
              line-height: 24px;
            }
          }
          &:nth-child(3n) {
            margin-right: 0;
          }
          &:hover {
            box-shadow: 0px 12px 64px rgb(0, 0, 0, 0.1);
            .homeproduct-imgbox {
              img {
                transform: scale(1.1, 1.1);
              }
            }
            .homeproduct-infobox {
              background-color: #5cc1a5;
              .homeproduct-title {
                color: #ffffff;
              }
              .homeproduct-textinfo {
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 941px) and (max-width: 1079px) {
  .homeproduct-container {
    padding: 90px 0 30px 0;
    .homeproduct-box {
      margin-bottom: 0px;
      .box-title {
        height: 42px;
        font-size: 32px;
        line-height: 42px;
        text-align: center;
        margin-bottom: 60px;
      }
      .homeproduct-wrap {
        display: flex;
        .homeproduct-item {
          flex: 0 0 32%;
          -ms-flex: 0 0 32%;
          margin-right: 2%;
          position: relative;
          overflow: hidden;
          .homeproduct-imgbox {
            width: 100%;
            height: auto;
            overflow: hidden;
            img {
              width: 100%;
              height: auto;
              transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
            }
          }
          .homeproduct-infobox {
            box-sizing: content-box;
            padding: 0 16px 40px 24px;
            .homeproduct-title {
              box-sizing: content-box;
              padding-top: 24px;
              height: 24px;
              font-size: 18px;
              line-height: 24px;
            }
            .homeproduct-textinfo {
              margin-top: 20px;
              height: 72px;
              font-size: 16px;
              line-height: 24px;
            }
          }
          &:nth-child(3n) {
            margin-right: 0;
          }
          &:hover {
            box-shadow: 0px 12px 64px rgb(0, 0, 0, 0.1);
            .homeproduct-imgbox {
              img {
                transform: scale(1.1, 1.1);
              }
            }
            .homeproduct-infobox {
              background-color: #5cc1a5;
              .homeproduct-title {
                color: #ffffff;
              }
              .homeproduct-textinfo {
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 769px) and (max-width: 940px) {
  .homeproduct-container {
    padding: 40px 0 30px 0;
    animation-delay: 20s;
    animation-name: fadeInUp;
    .homeproduct-box {
      margin-bottom: 0px;
      .box-title {
        height: 42px;
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 42px;
        color: #202020;
        text-align: center;
        margin-bottom: 40px;
      }
      .homeproduct-wrap {
        display: flex;
        // height: 30vw;
        .homeproduct-item {
          flex: 0 0 32%;
          -ms-flex: 0 0  32%;
          margin-right: 2%;
          position: relative;
          overflow: hidden;
          .homeproduct-infobox {
            background:  rgba(248, 248, 248, 0.39);;
            padding: 0 14px 24px 16px;
            .homeproduct-title {
              padding-top: 10px;
              height: 18px;
              font-size: 14px;
              line-height: 18px;
            }
            .homeproduct-textinfo {
              margin-top: 10px;
              height: 54px;
              font-size: 14px;
              line-height: 18px;
            }
          }
          &:nth-child(3n) {
            margin-right: 0;
          }
          &:hover {
            box-shadow: 0px 12px 64px rgb(0, 0, 0, 0.1);
            img {
              transform: scale(1.1, 1.1);
            }
            &::after {
              opacity: 1;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .homeproduct-container {
    padding: 40px 0 30px 0;
    animation-delay: 20s;
    animation-name: fadeInUp;
    .homeproduct-box {
      margin-bottom: 0px;
      .box-title {
        height: 42px;
        font-size: 28px;
        line-height: 42px;
        margin-bottom: 40px;
      }
      .homeproduct-wrap {
        display: block;
        // height: 30vw;
        .homeproduct-item {
          margin-right: 0;
          margin-bottom: 20px;
          position: relative;
          overflow: hidden;
          .homeproduct-infobox {
            margin-top: -2px;
            background:  rgba(248, 248, 248, 0.39);;
            padding:2px 14px 24px 16px;
            .homeproduct-title {
              padding-top:10px;
              height:18px;
              font-size: 14px;
              line-height: 18px;
            }
            .homeproduct-textinfo {
              margin-top:10px;
              height: 54px;
              font-size: 14px;
              line-height: 18px;
            }
          }
          &:nth-child(3n) {
            margin-right: 0;
          }
          &:hover {
            box-shadow: 0px 12px 64px rgb(0, 0, 0, 0.1);
            img {
              transform: scale(1.1, 1.1);
            }
            &::after {
              opacity: 1;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
</style>