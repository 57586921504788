<!--
 * @Author: 蒋威
 * @Date: 2022-05-23 09:32:08
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-07-06 11:01:59
 * @Description: 数据统计
-->
<template>
  <div class="response statistics-container">
    <div class="statistics-box">
      <div class="statistics-item">
        <div class="number" ref="donationRef">{{ website.revenueScale }}</div>
        <div class="lable" @click="digitalFlip">
          <!-- 2021年客户捐赠收入规模/元 -->
          {{ website.revenueScaleName||'2021年客户捐赠收入规模/元' }}
        </div>
      </div>
      <div class="statistics-item">
        <div class="number" ref="expenditureRef">
          {{ website.projectNum }}
        </div>
        <div class="lable" @click="digitalFlip">
          <!-- 2021年客户公益支出规模/元 -->
          {{ website.projectNumName||'2021年客户公益支出规模/元' }}
        </div>
      </div>
      <div class="statistics-item statistics-recipientsNum">
        <div class="number" ref="assistedRef">{{ website.recipientsNum }}</div>
        <!-- 客户公益总资产规模/元 -->
        <div class="lable">{{ website.recipientsNumName||'客户公益总资产规模/元' }}</div>
      </div>
      <div class="statistics-item statistics-projectNum">
        <div class="number" ref="projectRef">{{ website.supportingNum }}</div>
        <!-- 支撑公益项目/个 -->
        <div class="lable">{{ website.supportingNumName ||'支撑公益项目/个'}}</div>
      </div>
    </div>
    <!-- <Arrow /> -->
  </div>
</template>

<script>
import Arrow from '@/components/home/Arrow.vue'
export default {
  components: {
    Arrow,
  },
  props: {
    website: {
      type: Object,
    },
  },
  data() {
    return {}
  },
  methods: {
    // 文字变化效果
    // showfractValue是否显示小数点
    numberGrow(ele, value, showfractValue = false, time = 200) {
      // debugger;
      //间隔
      if (value - 0 <= 0) {
        ele.innerHTML = value
        return
      }
      let interval = (2 / (value + '').length).toFixed(3) - 0
      if (interval > 0.3) {
        interval = 0.3
      }
      // 整数部分
      let wholeValue = Math.trunc(value)
      // 小数部分
      let fractValue = ''
      const index = (value + '').indexOf('.')
      if (index > -1) {
        fractValue = '.' + (value + '').slice(index + 1)
      }
      //【这里调速度 1 ，步进值， 通俗地讲，就是每次跳的时候，增加的一个增量】
      let step = parseInt((wholeValue * 100) / (2 * 1000))
      // 设置当前值(这个值是计时器持续运行时，每次页面上显示的跳动值，不是最终的那个具体值)
      let current = 0
      // 设置开始值
      let start = 0
      // 设置定时器，用来反复横跳的，哈哈哈
      let t = setInterval(() => {
        // 每次增加一点步进值
        start += step
        // 开始值大于传过来的的值，说明 到点了，不用 继续横跳了
        if (start > wholeValue) {
          clearInterval(t)
          // 把穿过的值赋给start，结束
          start = wholeValue
          // 清掉计时器
          t = null
        }
        if (start == 0) {
          start = wholeValue
          clearInterval(t)
        }
        // 当前值等于开始值，那就结束
        if (value === 0) {
          return
        }
        current = start
        // 正则
        ele.innerHTML = showfractValue
          ? current.toString().replace(/(\d)(?=(?:\d{3}[+]?)+$)/g, '$1,') +
            fractValue
          : current.toString().replace(/(\d)(?=(?:\d{3}[+]?)+$)/g, '$1,')
      }, interval * time)
      // 【这里调速度 2， 通俗地讲，这里是页面上，肉眼能看到的跳动频率】
      // 本来想设置成 秒 *1000的，但是实在太慢了，就改成 *100了
    },
    /**
     * @Auth: 蒋威
     * @Date: 2022-05-26 11:31:22
     * @Description: 数字滚动
     */

    digitalFlip() {
      this.$nextTick(() => {
        this.numberGrow(
          this.$refs.donationRef,
          this.website.revenueScale,
          true,
          200
        )
        this.numberGrow(
          this.$refs.expenditureRef,
          this.website.projectNum,
          true,
          200
        )
        this.numberGrow(
          this.$refs.projectRef,
          this.website.supportingNum,
          false,
          200
        )
        this.numberGrow(
          this.$refs.assistedRef,
          this.website.recipientsNum,
          true,
          200
        )
      })
    },
  },
  mounted() {
    this.digitalFlip()
  },
}
</script>

<style lang="scss" scoped>
.statistics-container {
  padding: 65px 0 20px 0;
  .statistics-box {
    display: flex;
    margin-bottom: 10px;
    .statistics-item {
      position: relative;
      flex: 1;
      &::after {
        content: '';
        position: absolute;
        top: 15px;
        bottom: 15px;
        right: 0px;
        border: 1px solid #bcbcbc;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
      .number {
        height: 60px;
        text-align: center;
        font-size: 48px;
        font-family: DIN;
        font-weight: bold;
        line-height: 60px;
        color: #232f33;
        opacity: 1;
      }
      .lable {
        // margin-top: 8px;
        height: 22px;
        text-align: center;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        opacity: 1;
      }
    }
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1366px) {
  .statistics-container {
    padding: 65px 0 20px 0;
    .statistics-box {
      display: flex;
      margin-bottom: 10px;
      .statistics-item {
        position: relative;
        flex: 1;
        &::after {
          content: '';
          position: absolute;
          top: 15px;
          bottom: 15px;
          right: 0px;
          border: 1px solid #bcbcbc;
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
        .number {
          height: 48px;
          text-align: center;
          font-size: 32px;
          line-height: 48px;
        }
        .lable {
          height: 22px;
          text-align: center;
          font-size: 16px;
          line-height: 22px;
          opacity: 1;
        }
      }
    }
  }
}
@media only screen and (min-width: 941px) and (max-width: 1199px) {
  .statistics-container {
    padding: 65px 0 5px 0;
    .statistics-box {
      display: flex;
      margin-bottom: 10px;
      flex-wrap: wrap;
      .statistics-item {
        position: relative;
        flex: 0 0 50%;
        margin-bottom: 20px;
        &::after {
          content: '';
          position: absolute;
          top: 15px;
          bottom: 15px;
          right: 0px;
          border: none;
        }
        &:nth-child(2n) {
          &::after {
            display: none;
          }
        }
        .number {
          height: 48px;
          text-align: center;
          font-size: 32px;
          line-height: 48px;
        }
        .lable {
          height: 22px;
          text-align: center;
          font-size: 16px;
          line-height: 22px;
          opacity: 1;
        }
      }
    }
  }
}
@media only screen and (min-width: 769px) and (max-width: 940px) {
  .statistics-container {
    padding: 65px 0 5px 0;
    .statistics-box {
      display: flex;
      margin-bottom: 10px;
      flex-wrap: wrap;
      .statistics-item {
        position: relative;
        flex: 0 0 50%;
        margin-bottom: 15px;
        &::after {
          content: '';
          position: absolute;
          top: 15px;
          bottom: 15px;
          right: 0px;
          border: none;
        }
        &:nth-child(2n) {
          &::after {
            display: none;
          }
        }
        .number {
          height: 48px;
          text-align: center;
          font-size: 32px;
          line-height: 48px;
        }
        .lable {
          height: 22px;
          text-align: center;
          font-size: 16px;
          line-height: 22px;
          opacity: 1;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .statistics-container {
    padding: 50px 0 10px 0;
    .statistics-box {
      display: flex;
      margin-bottom: 10px;
      flex-wrap: wrap;
      .statistics-item {
        position: relative;
        flex: 0 0 50%;
        margin-bottom: 10px;
        &::after {
          content: '';
          position: absolute;
          top: 0px;
          bottom: 0px;
          right: 0px;
          border: none;
          transform: scale(0.5);
        }
        &:nth-child(2n) {
          &::after {
            display: none;
          }
        }
        .number {
          height: 36px;
          font-size: 26px;
          line-height: 36px;
        }
        .lable {
          height: 22px;
          font-size: 12px;
          line-height: 22px;
          opacity: 1;
        }
      }
    }
  }
}
</style>