<!--
 * @Author: 蒋威
 * @Date: 2022-05-23 14:04:00
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-30 09:03:27
 * @Description:  箭头
-->
<template>
  <div class="arrow-box">
    <span class="arrow-container pointer" @click="pageTurning($event)">
      <i class="iconfont icon-xialajiantouxiao"></i>
    </span>
  </div>
</template>

<script>
export default {
  methods: {
    // 下一屏
    pageTurning(e) {
      const fontSize = window.document.documentElement.style.fontSize.replace(
        'px',
        ''
      )
      document.documentElement.scrollTop = e.pageY - 24* fontSize/100
    },
  },
}
</script>

<style lang="scss" scoped>
.arrow-box {
  text-align: center;
  .arrow-container {
    display: inline-block;
    transition: all 0.3s;
    width: 40px;
    height: 40px;
    background: rgba(255, 255, 255, 0.39);
    border: 1px solid #ececec;
    border-radius: 50%;
    opacity: 0.8;
    margin-top: 5;
    i {
      color: #666666;
      font-size: 20px;
      font-weight: 700;
      line-height: 40px;
    }
    &:hover {
      transform: scale(1.1, 1.1);
    }
  }
}

@media only screen and (max-width: 940px) {
  .arrow-box {
    display: none;
  }
}
</style>