<!--
 * @Author: 蒋威
 * @Date: 2022-05-23 14:37:22
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-08-08 10:42:01
 * @Description: 公司资讯
-->
<template>
  <div class="homeInformation-container" v-show="list.length > 0">
    <div class="response homeInformation-box">
      <div class="box-title wow bounceInUp">新闻资讯</div>
      <div class="homeInformation-wrap">
        <template v-for="(item, index) in list">
          <div
            v-if="index < 3"
            data-wow-delay="0.1s"
            class="homeInformation-item pointer wow lightSpeedIn"
            :key="index"
            @click="toDetail(item)"
          >
            <div class="img-box">
              <img v-lazy="item.fileUrl" alt="" />
            </div>
            <div class="homeInformation-infobox">
              <div class="homeInformation-title ellipsis" :title="item.title">
                {{ item.title }}
              </div>
              <div class="homeInformation-textinfo ellipsis-2">
                {{ item.description }}
              </div>
              <div class="homeInformation-time">
                {{ $getDate(item.publishTime||item.createdAt, 'YMD') }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- <Arrow /> -->
  </div>
</template>

<script>
import { homeNews } from '@/api/homePage/index.js'
import Arrow from '@/components/home/Arrow.vue'
export default {
  inject: ['reload'],
  components: {
    Arrow,
  },
  data() {
    return {
      dataList: [
        {
          fileUrl:
            'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
        },
      ],
      list: [],
    }
  },
  methods: {
    async homeNews() {
      const res = await homeNews(3)

      if (res.code === this.$httpCode) {
        this.list = res.data || []
      }
    },
    toDetail(item) {
      localStorage.setItem('dcrumbName', item.title)
      this.reload()
      this.$router.push({
        name: 'newsInfo',
        query: { id: item.id },
      })
    },
  },
  created() {
    this.homeNews()
  },
}
</script>

<style  lang="scss" scoped>
@import '@/assets/css/fontsize.scss';
.homeInformation-container {
  .homeInformation-box {
    margin: 90px auto;
    .box-title {
      height: 42px;
      font-size: 32px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 42px;
      color: #202020;
      text-align: center;
      margin-bottom: 100px;
    }
    .homeInformation-wrap {
      display: flex;
      .homeInformation-item {
        flex: 0 0 410px;
        -ms-flex: 0 0 410px;
        margin-right: 35px;
        overflow: hidden;
        position: relative;
        .img-box {
          width: 100%;
          overflow: hidden;
        }
        img {
          height: 256px;
          transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
        }
        .homeInformation-infobox {
          margin-top: -2px;
          padding: 30px 20px 30px 20px;
          background: rgba(248, 248, 248, 0.39);
          .homeInformation-title {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            height: 48px;
            line-height: 24px;
            color: #202020;
          }
          .homeInformation-textinfo {
            height: 48px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 24px;
            color: #666666;
            margin-bottom: 24px;
          }
          .homeInformation-time {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            height: 22px;
            line-height: 22px;
            color: #666666;
          }
        }
        &:nth-child(3n) {
          margin-right: 0;
        }
        &:hover {
          box-shadow: 0px 12px 64px rgb(0, 0, 0, 0.1);
          .homeInformation-infobox {
            background-color: #5cc1a5;
            .homeInformation-title,
            .homeInformation-textinfo,
            .homeInformation-time {
              color: #fff;
            }
          }
          img {
            transform: scale(1.1, 1.1);
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 1080px) and (max-width: 1299px) {
  .homeInformation-container {
    .homeInformation-box {
      margin: 90px auto;
      .box-title {
        margin-bottom: 90px;
      }
      .homeInformation-wrap {
        display: flex;
        .homeInformation-item {
          flex: 0 0 32%;
          -ms-flex: 0 0 32%;
          margin-right: 2%;
          overflow: hidden;
          position: relative;
          img {
            width: 100%;
            height: auto;
            transition: none;
          }
          .homeInformation-infobox {
            padding: 30px 20px 30px 20px;
            background: rgba(248, 248, 248, 0.39);
            .homeInformation-title {
              font-size: 18px;
              height: 48px;
              line-height: 24px;
            }
            .homeInformation-textinfo {
              height: 48px;
              font-size: 16px;
              line-height: 24px;
              margin-bottom: 24px;
            }
            .homeInformation-time {
              font-size: 16px;
              height: 22px;
              line-height: 22px;
            }
          }
          &:nth-child(3n) {
            margin-right: 0;
          }
          &:hover {
            img {
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 941px) and (max-width: 1079px) {
  .homeInformation-container {
    .homeInformation-box {
      margin: 90px auto;
      .box-title {
        margin-bottom: 80px;
      }
      .homeInformation-wrap {
        display: flex;
        .homeInformation-item {
          flex: 0 0 32%;
          -ms-flex: 0 0 32%;
          margin-right: 2%;
          overflow: hidden;
          position: relative;
          img {
            width: 100%;
            height: auto;
            transition: none;
          }
          .homeInformation-infobox {
            padding: 20px 20px 20px 20px;
            background: rgba(248, 248, 248, 0.39);
            .homeInformation-title {
              font-size: 18px;
              height: 38px;
              line-height: 24px;
            }
            .homeInformation-textinfo {
              height: 48px;
              font-size: 16px;
              line-height: 24px;
              margin-bottom: 18px;
            }
            .homeInformation-time {
              font-size: 16px;
              height: 22px;
              line-height: 22px;
            }
          }
          &:nth-child(3n) {
            margin-right: 0;
          }
          &:hover {
            img {
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 769px) and (max-width: 940px) {
  .homeInformation-container {
    .homeInformation-box {
      margin: 60px auto;
      .box-title {
        height: 42px;
        font-size: 28px;
        line-height: 42px;
        margin-bottom: 40px;
      }
      .homeInformation-wrap {
        display: flex;
        .homeInformation-item {
          flex: 0 0 32%;
          -ms-flex: 0 0 32%;
          margin-right: 2%;
          overflow: hidden;
          position: relative;
          img {
            width: 100%;
            height: auto;
            transition: none;
          }
          .homeInformation-infobox {
            padding: 10px 14px;
            background: rgba(248, 248, 248, 0.39);
            .homeInformation-title {
              font-size: 14px;
              height: 28px;
              line-height: 18px;
            }
            .homeInformation-textinfo {
              height: 54px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 18px;
              color: #666666;
              margin-bottom: 10px;
            }
            .homeInformation-time {
              font-size: 14px;
              height: 18px;
              line-height: 18px;
              color: #666666;
            }
          }
          &:hover {
            img {
              transform: none;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .homeInformation-container {
    .homeInformation-box {
      margin: 60px auto;
      .box-title {
        height: 42px;
        font-size: 28px;
        line-height: 42px;
        margin-bottom: 60px;
      }
      .homeInformation-wrap {
        display: block;
        .homeInformation-item {
          flex: 0 0 32%;
          -ms-flex: 0 0 32%;
          margin-right: 0%;
          overflow: hidden;
          position: relative;
          margin-bottom: 20px;
          img {
            width: 100%;
            height: auto;
            transition: none;
          }
          .homeInformation-infobox {
            padding: 10px 14px;
            background: #f8f8f8;
            .homeInformation-title {
              font-size: 14px;
              height: 28px;
              line-height: 18px;
            }
            .homeInformation-textinfo {
              height: 54px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 18px;
              color: #666666;
              margin-bottom: 10px;
            }
            .homeInformation-time {
              font-size: 14px;
              height: 18px;
              line-height: 18px;
              color: #666666;
            }
          }
          &:hover {
            img {
              transform: none;
            }
          }
        }
      }
    }
  }
}
</style>